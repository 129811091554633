<template>
  <div class="view pa24">
    <div class="d-flex">
      <div>
        <el-button class="ma" type="primary" @click="add"
        >+ 新增开发区</el-button
        >
      </div>
    </div>
    <commonTable
        :tableData="tableData"
        @handleSelectionChange="handleSelectionChange"
        :total="total"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
        :currentPage="currentPage"
        :loading="loading"
    >
      <template v-slot:table>
        <el-table-column type="selection" />
        <el-table-column prop="name" align="center" label="开发区名称" />
        <el-table-column prop="ifTop" align="center" label="置顶" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-switch
                @change="saveStatus(scope.row)"
                v-model="scope.row.ifTop"
                active-color="#51CDCB"
                inactive-color="#dadde5">
            </el-switch>
          </template>
        </el-table-column>
                <el-table-column prop="createTime" align="center" label="创建时间" />
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="primary" plain @click="open(scope.row)"
            >编辑</el-button
            >
            <customPopconfirm
                class="ma ml10 mb10"
                confirm-button-text="确定"
                cancel-button-text="取消"
                @confirm="del(scope.row.kfqAreaId)"
                title="确认删除？">
              <el-button type="danger" slot="reference" plain>删除</el-button>
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import customPopconfirm from "@/components/common/customPopconfirm";
import {addKfqArea,upKfqArea,delKfqArea,selectKfqAreaPageList,upKfqAreaTop} from "@/api/serviceDevelopmentZone";
export default {
  name: "allFWFKQList",
  components: {
    commonTable,
    customPopconfirm
  },
  props:{
    jurisdiction:{
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableData: [],
      filterName: "",
      row: "",
      delGoodsT: [],
      currentPage: 1,
      loading:false,
      total: 0, //总条数
      pageNum: 1,
      pageSize: 10,
      dialogImageUrl: "",
      kfqAreaId:"",
    };
  },
  computed: {

  },
  mounted(){
    this.kfqRegionId = this.$route.query.kfqRegionId?this.$route.query.kfqRegionId:window.sessionStorage.getItem('kfqRegionId')
    console.log( this.kfqRegionId)
    this.queryPage();
  },
  methods: {
    async queryPage() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        associationId: localStorage.getItem("associationId"),
        kfqRegionId:this.kfqRegionId
      };
      try {
        this.loading = true;
        const result = await selectKfqAreaPageList(data);
        this.loading = false;
        const { total, list } = result.data.pageInfo;
        for (let i in list) {
          list[i].createTime = this.renderTime(list[i].createTime)
          if (list[i].ifTop == 0) {
            list[i].ifTop = true
          }else {
            list[i].ifTop = false
          }
        }
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.tableData = []
        this.loading = false;
      }
    },
    renderTime(date) {
      var dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
    handleSelectionChange(value) {
      this.delGoodsT = value;
    },
    /**@method 编辑
     * @param {Object} val - 当前点击行的值
     */
    open(val) {
      this.$router.push({ path: "/addFWFKQ",query:{kfqRegionId:this.kfqRegionId,kfqAreaId:val.kfqAreaId} });
    },
    //删除弹窗
    async del(val) {
      try {
        await delKfqArea({ kfqAreaId: val });
        await this.queryPage();
      } catch (error) {
        console.log(error);
      }
    },
    //当前页变更
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryPage();
    },
    //当前页查询数据条数变更
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryPage();
    },
    async saveStatus(row){
      if(row){
        let ajax = {
          kfqAreaId:row.kfqAreaId,
          associationId: localStorage.getItem("associationId"),
        }
        const result = await upKfqAreaTop(ajax);
        console.log("result:",result)
        this.$message({
          type: "success",
          message: "保存成功",
        });
        await this.queryPage();
      }else{
        this.$message({
          type: "error",
          message: "排序不能为空",
        });
      }
    },

    add(){
      this.$router.push({ path: "/addFWFKQ",query:{kfqRegionId:this.kfqRegionId} });
    }
  },
};
</script>

<style lang="scss" scoped></style>






