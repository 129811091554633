import httpUtil from "@/utils/httpUtil";

/**新增文章 */
export const addKfqArticle =  params => httpUtil.post("/api/assPc/kfqArea/addKfqArticle", params);

/**查询文章 */
export const selectKfqArticlePageList =  params => httpUtil.post("/api/assPc/kfqArea/selectKfqArticlePageList", params);

/**修改文章 */
export const upKfqArticle =  params => httpUtil.post("/api/assPc/kfqArea/upKfqArticle", params);

/**删除文章 */
export const delKfqArticle =  params => httpUtil.post("/api/assPc/kfqArea/delKfqArticle", params);

/**文章置顶 */
export const upKfqArticleTop =  params => httpUtil.post("/api/assPc/kfqArea/upKfqArticleTop", params);

/** 添加开发区类型 */
export const addKfqType =  params => httpUtil.post("/api/assPc/kfqArea/addKfqType", params);

/** 修改开发区类型 */
export const upKfqType =  params => httpUtil.post("/api/assPc/kfqArea/upKfqType", params);

/** 删除开发区类型 */
export const delKfqType =  params => httpUtil.post("/api/assPc/kfqArea/delKfqType", params);

/** 开发区类型列表 */
export const selectKfqTypePageList =  params => httpUtil.post("/api/assPc/kfqArea/selectKfqTypePageList", params);



/** 添加开发区 */
export const addKfqArea =  params => httpUtil.post("/api/assPc/kfqArea/addKfqArea", params);

/** 修改开发区 */
export const upKfqArea =  params => httpUtil.post("/api/assPc/kfqArea/upKfqArea", params);

/** 删除开发区 */
export const delKfqArea =  params => httpUtil.post("/api/assPc/kfqArea/delKfqArea", params);

/** 开发区列表 */
export const selectKfqAreaPageList =  params => httpUtil.post("/api/assPc/kfqArea/selectKfqAreaPageList", params);


/** 添加开发区地区 */
export const addKfqRegion =  params => httpUtil.post("/api/assPc/kfqArea/addKfqRegion", params);

/** 修改开发区地区 */
export const upKfqRegion =  params => httpUtil.post("/api/assPc/kfqArea/upKfqRegion", params);

/** 删除开发区地区 */
export const delKfqRegion =  params => httpUtil.post("/api/assPc/kfqArea/delKfqRegion", params);

/** 开发区列表地区 */
export const selectKfqRegionPageList =  params => httpUtil.post("/api/assPc/kfqArea/selectKfqRegionPageList", params);

/** 开发区地区详情 */
export const queryKfqAreaById =  params => httpUtil.post("/api/assPc/kfqArea/queryKfqAreaById", params);

/** 开发区地区颜色icon*/
export const selectMapColorPageList =  params => httpUtil.post("/api/assPc/kfqArea/selectMapColorPageList", params);

/** 开发区地区置顶 */
export const upKfqRegionTop =  params => httpUtil.post("/api/assPc/kfqArea/upKfqRegionTop", params);

/** 开发区地区置顶 */
export const upKfqAreaTop =  params => httpUtil.post("/api/assPc/kfqArea/upKfqAreaTop", params);



